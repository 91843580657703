import { StateKind } from "@common/state";
import { getServerAddress } from "@core/appVariables";
import { fetchClient } from "@core/fetchClient";
import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { IDashboardAdditionalChargeDto, IDashboardChargesDto, IDashboardGlCodeSummaryItemDto, IDashboardKpiStatusFilterDto, IDashboardSpendByMonthDto, IDashboardVarianceDto } from "contracts/dashboard";

interface IKpiStatus {
    spendByMonthStatus: StateKind;
    chargesStatus: StateKind;
    varianceStatus: StateKind;
    costAdditionalChargesStatus: StateKind;
    glSummaryStatus: StateKind;
}

interface IKpiState {
    chartStatus: IKpiStatus;
    status: StateKind;

    spendByMonth?: IDashboardSpendByMonthDto[];
    charges?: IDashboardChargesDto[];
    variance?: IDashboardVarianceDto[];
    costAdditionalCharges?: IDashboardAdditionalChargeDto[];
    glSummary?: IDashboardGlCodeSummaryItemDto[];
}

const initialState: IKpiState = {
    status: 'notLoaded',
    chartStatus: {
        spendByMonthStatus: 'notLoaded',
        chargesStatus: 'notLoaded',
        varianceStatus: 'notLoaded',
        costAdditionalChargesStatus: 'notLoaded',
        glSummaryStatus: 'notLoaded'
    }
}

export const getSpendByMonth = createAsyncThunk(
    'kpi-dashboard/common/spend-by-month',
    async (request: IDashboardKpiStatusFilterDto) => {
        const response = await fetchClient().post<IDashboardKpiStatusFilterDto, IDashboardSpendByMonthDto[]>(`${getServerAddress('/analytics/api')}/dashboard-kpi/spend-by-month`, request);

        return response;
    }
)

export const getChargesData = createAsyncThunk(
    'kpi-dashboard/common/charges',
    async (request: IDashboardKpiStatusFilterDto) => {
        const response = await fetchClient().post<IDashboardKpiStatusFilterDto, IDashboardChargesDto[]>(`${getServerAddress('/analytics/api')}/dashboard-kpi/charges-by-month`, request);

        return response;
    }
)

export const getVarianceTotal = createAsyncThunk(
    'kpi-dashboard/common/variance',
    async (request: IDashboardKpiStatusFilterDto) => {
        const response = await fetchClient().post<IDashboardKpiStatusFilterDto, IDashboardVarianceDto[]>(`${getServerAddress('/analytics/api')}/dashboard-kpi/variance`, request);

        return response;
    }
)

export const getCostAdditionalCharge = createAsyncThunk(
    'kpi-dashboard/common/cost-additional-charge',
    async (request: IDashboardKpiStatusFilterDto) => {
        const response = await fetchClient().post<IDashboardKpiStatusFilterDto, IDashboardAdditionalChargeDto[]>(`${getServerAddress('/analytics/api')}/dashboard-kpi/additional-charge`, request);

        return response;
    }
)

export const getGlSummary = createAsyncThunk(
    'kpi-dashboard/common/gl-summary',
    async (request: IDashboardKpiStatusFilterDto) => {
        const response = await fetchClient().post<IDashboardKpiStatusFilterDto, IDashboardGlCodeSummaryItemDto[]>(`${getServerAddress('/analytics/api')}/dashboard-kpi/gl-summary`, request);

        return response;
    }
)

export const kpiStore = createSlice({
    name: 'kpi-dashboard',
    initialState,
    reducers: {
    },
    extraReducers: (builder) => {
        builder
            .addCase(getSpendByMonth.pending, (state: IKpiState) => {
                state.chartStatus = { ...state.chartStatus, spendByMonthStatus: 'loading' };
            })
            .addCase(getSpendByMonth.fulfilled, (state: IKpiState, action: PayloadAction<IDashboardSpendByMonthDto[] | undefined>) => {
                state.spendByMonth = action.payload;
                state.chartStatus = { ...state.chartStatus, spendByMonthStatus: 'loaded' };
            })
            .addCase(getSpendByMonth.rejected, (state: IKpiState, error) => {
                state.chartStatus = { ...state.chartStatus, spendByMonthStatus: 'failed' };
            })
            .addCase(getChargesData.pending, (state: IKpiState) => {
                state.chartStatus = { ...state.chartStatus, chargesStatus: 'loading' };
            })
            .addCase(getChargesData.fulfilled, (state: IKpiState, action: PayloadAction<IDashboardChargesDto[] | undefined>) => {
                state.charges = action.payload;
                state.chartStatus = { ...state.chartStatus, chargesStatus: 'loaded' };
            })
            .addCase(getChargesData.rejected, (state: IKpiState, error) => {
                state.chartStatus = { ...state.chartStatus, chargesStatus: 'failed' };
            })
            .addCase(getVarianceTotal.pending, (state: IKpiState) => {
                state.chartStatus = { ...state.chartStatus, varianceStatus: 'loading' };
            })
            .addCase(getVarianceTotal.fulfilled, (state: IKpiState, action: PayloadAction<IDashboardVarianceDto[] | undefined>) => {
                state.variance = action.payload;
                state.chartStatus = { ...state.chartStatus, varianceStatus: 'loaded' };
            })
            .addCase(getVarianceTotal.rejected, (state: IKpiState, error) => {
                state.chartStatus = { ...state.chartStatus, varianceStatus: 'failed' };
            })
            .addCase(getCostAdditionalCharge.pending, (state: IKpiState) => {
                state.chartStatus = { ...state.chartStatus, costAdditionalChargesStatus: 'loading' };
            })
            .addCase(getCostAdditionalCharge.fulfilled, (state: IKpiState, action: PayloadAction<IDashboardAdditionalChargeDto[] | undefined>) => {
                state.costAdditionalCharges = action.payload;
                state.chartStatus = { ...state.chartStatus, costAdditionalChargesStatus: 'loaded' };
            })
            .addCase(getCostAdditionalCharge.rejected, (state: IKpiState, error) => {
                state.chartStatus = { ...state.chartStatus, costAdditionalChargesStatus: 'failed' };
            })
            .addCase(getGlSummary.pending, (state: IKpiState) => {
                state.chartStatus = { ...state.chartStatus, glSummaryStatus: 'loading' };
            })
            .addCase(getGlSummary.fulfilled, (state: IKpiState, action: PayloadAction<IDashboardGlCodeSummaryItemDto[] | undefined>) => {
                state.glSummary = action.payload;
                state.chartStatus = { ...state.chartStatus, glSummaryStatus: 'loaded' };
            })
            .addCase(getGlSummary.rejected, (state: IKpiState, error) => {
                state.chartStatus = { ...state.chartStatus, glSummaryStatus: 'failed' };
            })
    }
});

export const KpiAction = kpiStore.actions;

export default kpiStore.reducer;