import { createSelector } from "@reduxjs/toolkit";
import { RootState } from "./store";
import { ReportTypeEnum } from "contracts/amos";
import { RoleHelper } from "./roleHelper";

export class PermissionHelper {
    public static readonly permissions = {
        createOrder: 'CreateOrder',
        viewRate: 'ViewRate',
        multiEvents: 'MultiEvents'
    };
}

export interface IUserAccessInfo {
    permissions: string | undefined;
    reportTypes: string | undefined;
    role: string | undefined;
}

export const selectAccessInfo = createSelector(
    [
        (state: RootState) => state.auth.user?.permissions,
        (state: RootState) => state.auth.user?.reportTypes,
        (state: RootState) => state.auth.user?.role,
    ],

    (permissions, reportTypes, role): IUserAccessInfo => {
        return { permissions, reportTypes, role }
    }
);

const hasPermission = (accessInfo: IUserAccessInfo, permission: string): boolean => {
    return accessInfo.permissions?.includes(permission) || false;
};

const hasReportType = (accessInfo: IUserAccessInfo, type: ReportTypeEnum): boolean => {
    return accessInfo.reportTypes?.split(',').includes(type.toString()) || false;
};

const hasRole = (accessInfo: IUserAccessInfo, role: string): boolean => {
    return accessInfo.role?.includes(role) || false;
};

const isSuperAdmin = (accessInfo: IUserAccessInfo): boolean => {
    return hasRole(accessInfo, RoleHelper.roles.superAdmin);
};

const checkAccess = (accessInfo: IUserAccessInfo, permission: string | null, reportType: ReportTypeEnum | null): boolean => {
    if (isSuperAdmin(accessInfo)) return true;
    if (permission && hasPermission(accessInfo, permission)) return true;
    if (reportType && hasReportType(accessInfo, reportType)) return true;
    return false;
};

export const isAdmin = (accessInfo: IUserAccessInfo): boolean => {
    return hasRole(accessInfo, RoleHelper.roles.superAdmin) || hasRole(accessInfo, RoleHelper.roles.admin);
};

export const createOrderAccess = (accessInfo: IUserAccessInfo): boolean => {
    return checkAccess(accessInfo, PermissionHelper.permissions.createOrder, null);
};

export const viewRateAccess = (accessInfo: IUserAccessInfo): boolean => {
    return checkAccess(accessInfo, PermissionHelper.permissions.viewRate, null);
};

export const multiEventsAccess = (accessInfo: IUserAccessInfo): boolean => {
    return checkAccess(accessInfo, PermissionHelper.permissions.multiEvents, null);
};

export const anyReportsAccess = (accessInfo: IUserAccessInfo): boolean => {
    const relevantReports = [
        ReportTypeEnum.SFS,
        ReportTypeEnum.AdditionalCharges,
        ReportTypeEnum.OrderDetailsAssessorial,
        ReportTypeEnum.Accesories,
        ReportTypeEnum.Expedite,
        ReportTypeEnum.GLCode,
        ReportTypeEnum.Spend
    ];

    const hasRelevantReport = relevantReports.some(type => hasReportType(accessInfo, type));

    const reportTypesArray = accessInfo.reportTypes ? accessInfo.reportTypes.split(',') : [];
    const allReportsRelevant = reportTypesArray.every((type: string) => relevantReports.includes(Number(type)));

    if (hasRelevantReport && allReportsRelevant) {
        return viewRateAccess(accessInfo);
    }

    return reportTypesArray.length > 0 || isSuperAdmin(accessInfo);
};

export const reportAccess = (accessInfo: IUserAccessInfo, type: ReportTypeEnum): boolean => {
    if ([ReportTypeEnum.SFS, ReportTypeEnum.AdditionalCharges, ReportTypeEnum.OrderDetailsAssessorial, ReportTypeEnum.Accesories, ReportTypeEnum.Expedite, ReportTypeEnum.GLCode, ReportTypeEnum.Spend].includes(type)) {
        return (hasReportType(accessInfo, type) && viewRateAccess(accessInfo)) || isAdmin(accessInfo);
    }

    return checkAccess(accessInfo, null, type);
};
