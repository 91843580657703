import { AuthHelper } from '@core/authHelper';
import { Layout } from 'app/Layout';
import { lazy, Suspense } from 'react';
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';
import { AuxiliaryModal } from '@common/AuxiliaryModal/AuxiliaryModal';
import OrderDetail from 'orderDetail/OrderDetail';
import OrderCarrierRate from 'createOrder/@components/pricing/OrderCarrierRate';
import LoadingBox from '@common/@components/LoadingBox';
import { CreateOrderRouteGuard, ReportsRouteGuard, RouteGuard, ViewRateGuard } from '@common/RouteGuard';


export default function App() {
  const anonymousRoutes = [
    {
      path: AuthHelper.loginPath,
      component: lazy(() => import('auth/Login')),
    },
    {
      path: '/forgot-password',
      component: lazy(() => import('auth/ForgotPassword')),
    },
    {
      path: '/reset-password',
      component: lazy(() => import('auth/ResetPassword')),
    },
    {
      path: '/link-obsoleted',
      component: lazy(() => import('errorPages/@components/LinkObsoleted'))
    }
  ]

  const privateRoutes1 = [
    {
      path: '/',
      component: lazy(() => import('dashboard/Dashboard')),
    },
    // {
    //   path: '/powerbi',
    //   component: lazy(() => import('powerBI/PowerBI')),
    // },
    {
      path: '/dock-scheduler',
      component: lazy(() => import('dockScheduler/DockScheduler'))
    }
  ];

  const privateCreateOrderRoutes = [
    {
      path: '/create-order',
      component: lazy(() => import('createOrder/CreateOrder'))
    }
  ];

  const privateReportsRoutes = [
    {
      path: '/reports',
      component: lazy(() => import('reports/Reports'))
    },
    {
      path: 'report/apg/:id',
      component: lazy(() => import('reports/@components/apg/ApgDetails'))
    },
    {
      path: 'report/ldt/:id',
      component: lazy(() => import('reports/@components/ldt/LdtDetails'))
    },
    {
      path: 'report/sfs/:id',
      component: lazy(() => import('reports/@components/sfs/SfsDetails'))
    },
    {
      path: 'report/wpd/:id',
      component: lazy(() => import('reports/@components/wpd/WpdDetails'))
    },
    {
      path: 'report/additionalcharges/:id',
      component: lazy(() => import('reports/@components/additionalCharges/AdditionalChargesDetails'))
    },
    {
      path: 'report/orderdetailsassessorial/:id',
      component: lazy(() => import('reports/@components/orderDetailsAssessorial/OrderAssessorialDetails'))
    },
    {
      path: 'report/spend/:id',
      component: lazy(() => import('reports/@components/spend/SpendDetails'))
    },
    {
      path: 'report/accesories/:id',
      component: lazy(() => import('reports/@components/accesories/AccesoriesDetails'))
    },
    {
      path: 'report/expedite/:id',
      component: lazy(() => import('reports/@components/Expedite/ExpediteDetails'))
    },
    {
      path: 'report/glcode/:id',
      component: lazy(() => import('reports/@components/GLCode/GLCodeDetails'))
    },
  ];

  const privateKpiDashboardRoutes = [
    {
      path: '/kpi-dashboard',
      component: lazy(() => import('kpiDashboard/KPIDashboard'))
    }
  ]


  return (
    <BrowserRouter basename={process.env.PUBLIC_URL}>
      <Layout>
        <Suspense fallback={<LoadingBox />}>
          <Routes>

            {privateRoutes1.map((item, index) =>
              <Route path={item.path} key={index} element={
                <RouteGuard loginPath={AuthHelper.loginPath}>
                  {
                    <item.component />
                  }
                </RouteGuard>
              }
              >
                <Route path={`${item.path}/(order/:id)`} element={<AuxiliaryModal component={<OrderDetail />} />}></Route>
              </Route>
            )}

            {privateCreateOrderRoutes.map((item, index) =>
              <Route path={item.path} key={index} element={
                <CreateOrderRouteGuard homePath="/">
                  {
                    <item.component />
                  }
                </CreateOrderRouteGuard>
              }
              >
                <Route path={`${item.path}/(rate/:id)`} element={<AuxiliaryModal component={<OrderCarrierRate />} />}></Route>
              </Route>
            )}

            {privateKpiDashboardRoutes.map((item, index) =>
              <Route path={item.path} key={index} element={
                <ViewRateGuard homePath="/">
                  {
                    <item.component />
                  }
                </ViewRateGuard>
              }
              >
              </Route>
            )}

            {privateReportsRoutes.map((item, index) =>
              <Route path={item.path} key={index} element={
                <ReportsRouteGuard homePath="/">
                  {
                    <item.component />
                  }
                </ReportsRouteGuard>
              }
              >
                <Route path={`${item.path}/(rate/:id)`} element={<AuxiliaryModal component={<OrderCarrierRate />} />}></Route>
              </Route>
            )}

            {anonymousRoutes.map((item, index) =>
              <Route path={item.path} key={index} element={<item.component />} />
            )}

            {/* NotFound should be the last route */}
            {/* <Route component={NotFound} /> */}
            <Route path="*"
              element={
                <RouteGuard loginPath={AuthHelper.loginPath}>
                  {
                    <Navigate replace to="/" />
                  }
                </RouteGuard>
              }
            />
          </Routes>
        </Suspense>
      </Layout>
    </BrowserRouter>
  );
}
